/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: Snickers;
	src: url("./resources/SNICN___.TTF");
}

body {
	background: black;
}

.lds-ripple {
	display: inline-block;
	position: relative;
}
.lds-ripple div {
	position: absolute;
	border: 4px solid #1756a2;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

@keyframes lds-ripple {
	0% {
		top: 50%;
		left: 50%;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}

.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	animation: spin 2s linear infinite;
	display: inline-block;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.rainbow {
	background: linear-gradient(
			45deg,
			rgb(255, 0, 0) 0%,
			rgb(255, 154, 0) 10%,
			rgb(208, 222, 33) 20%,
			rgb(79, 220, 74) 30%,
			rgb(63, 218, 216) 40%,
			rgb(47, 201, 226) 50%,
			rgb(28, 127, 238) 60%,
			rgb(95, 21, 242) 70%,
			rgb(186, 12, 248) 80%,
			rgb(251, 7, 217) 90%,
			rgb(255, 0, 0) 100%
		)
		0% 0% / 300% 300%;
	animation: 4s linear 0s infinite normal none running rainbowMove;
}

.rainbow2 {
	border-radius: 16px;
	filter: blur(6px);
	position: absolute;
	inset: -2px;
	z-index: -1;
}

@keyframes rainbowMove {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
